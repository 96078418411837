<template>
  <div class="geral">
    <div class="folha page-break">
      <div class="space"></div>

      <table>
        <tbody>
          <tr>
            <td colspan="3">
              <div class="col1">
                <img
                  class="pb"
                  src="../../assets/logoEasy2019.png"
                  style="width:4cm"
                />
              </div>
              <div class="col2">
                <br /><span style="font-size:8pt;font-weight:600">{{
                  datahojeagora
                }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              Nome do Cliente: <strong>{{ boleto.nome }}</strong>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              Código de Cliente: <strong>{{ boleto.contrato }}</strong>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              Referência: numero de telefone: <strong>{{ boleto.gsm }}</strong>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <center>
                <b style="font-size:13pt;"
                  >Temos uma ótima notícia para VOCÊ!</b
                >
              </center>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              A Easycob, empresa credenciada pela TIM preparou uma oportunidade
              imperdível para você pagar sua dívida. Pague até o dia &nbsp;
              &nbsp; {{ boleto.vencimento }} &nbsp; &nbsp; o boleto abaixo:
            </td>
          </tr>
          <tr>
            <td colspan="3">
              VALOR DA DÍVIDA:
              <strong>R$ {{ boleto.valor_divida_str | dinheiro }}</strong>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              VALOR A PAGAR COM DESCONTO:
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <div
                class="banner"
                style='max-height:90pt;width:230pt;text-align: center;background: #fff url("imagens/new.png") no-repeat;background-size: 280px 80px;'
              >
                <b>R$ {{ boleto.valor_pagar_str }}</b>
              </div>
            </td>
          </tr>
          <tr style="border:2px solid #000;height:15vh;">
            <td>
              <b>Fatura(s):</b>
              <br />
              <code
                v-for="(fat, i) in boleto.faturas_str"
                :key="i"
                class="block"
              >
                {{ fat }} <br
              /></code>
            </td>
            <td>
              <v-row class="d-flex flex-row-reverse pa-4">
                <!--
                <qr-code
                  :text="texto"
                  :size="meusize"
                  error-level="L"
                ></qr-code> -->
              </v-row>
              <v-row class="d-flex flex-row-reverse pl-8 pr-8 pb-8 "
                ><!-- <p class="text-center">PAGUE COM PIX</p> -->
              </v-row>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <strong>
                Em caso de dúvidas ou outra forma de pagamento do débito, entre
                em contato com a Central de Atendimento da Easycob que teremos
                todo o interesse em buscar uma solução que atenda às suas
                necessidades.
              </strong>
            </td>
          </tr>
          <tr style="border:2px solid #000">
            <td colspan="3">
              <table>
                <tr>
                  <td>
                    <center style="font-size:8pt!important">
                      Telefones:<br /><br /><strong
                        style="font-size:8pt!important"
                        >(21) 2206-4884<br />ou<br />0800 888 2373</strong
                      >
                    </center>
                  </td>
                  <td>
                    <center style="font-size:8pt!important">
                      E-mail:<br /><br /><strong style="font-size:8pt!important"
                        >boleto@easboleto.com.br<br />Ou acessa nossa página:<br />www.easycob-n.com.br</strong
                      >
                    </center>
                  </td>
                  <td>
                    <center style="font-size:8pt!important">
                      Whatsapp:<br /><br /><strong
                        style="font-size:8pt!important"
                        >(21) 2206-4811</strong
                      >
                    </center>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    <center
                      style="font-weight:520;font-size:85%;letter-spacing:-0.5px;"
                    >
                      Nosso horário de atendimento é de segunda à sexta das 08h
                      às 20h e Sábados das 08h às 14h.
                    </center>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <strong
                >Caso já tenha efetuado o pagamento, favor
                desconsiderar.</strong
              >
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <center>
                <b
                  >Easycob Consultoria Treinamento e Assessoria Empresarial
                  Ltda.</b
                >
              </center>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <center>
                <span style="font-weight:520;font-size:9pt;"
                  >Dados do Credor: TIM CELULAR S.A - CNPJ/MF sob nº.
                  04.206.050/0001-80 sede Avenida Giovanni Gronchi, nº 7143,Vila
                  Andrade - São Paulo - SP - CEP 05724-005</span
                >
              </center>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="space" style="position:relative;margin:10px 0px;">
        <img
          src="imagens/recortar.png"
          style="max-height:20pt;position:absolute;bottom:0px;"
        />
        <hr />
      </div>
      <code>Pague nas Casas loterias ou Bancos</code>
      <table>
        <tbody>
          <tr style="border:2px solid #000">
            <td colspan="3">
              <table>
                <tr>
                  <td>
                    <span style="font-size:8pt!important"
                      >Código Cliente:
                      <strong style="font-size:8pt!important">{{
                        boleto.contrato
                      }}</strong></span
                    >
                  </td>
                  <td colspan="2">
                    <span style="font-size:8pt!important"
                      >Cliente:
                      <strong style="font-size:8pt!important">{{
                        boleto.nome
                      }}</strong></span
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="font-size:8pt!important"
                      >Valor:
                      <strong style="font-size:8pt!important">{{
                        boleto.valor_pagar_str
                      }}</strong></span
                    >
                  </td>
                  <td colspan="2">
                    <span style="font-size:8pt!important"
                      >Vencimento:
                      <strong style="font-size:8pt!important">{{
                        boleto.vencimento
                      }}</strong></span
                    >
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    <center
                      style="letter-spacing:-0.5px;text-align:right;font-size:8pt!important"
                    >
                      (NÃO RECEBER APÓS O VENCIMENTO)
                    </center>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="space">
        {{ boleto.ld }}<br />
        <div id="codb"></div>
        
      </div>
      <br><br><br><br><br>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueQRCodeComponent from "vue-qrcode-component";
Vue.component("qr-code", VueQRCodeComponent);
import { api } from "@/conf/api";
import barcode from "@/conf/barcode";
export default {
  name: "BoletoTim",
  data() {
    return {
      boleto: null,
      datahojeagora: "",
      meusize: 200,
      texto: "",
    };
  },
  filters: {
    dinheiro: function(value) {
      return parseFloat(value)
        .toFixed(2)
        .replace(".", ",");
    },
    dataF: function(d2) {
      let d = d2.substr(0, 10);
      return d.substr(8, 2) + "/" + d.substr(5, 2) + "/" + d.substr(0, 4);
    },
  },
  async beforeMount() {
    this.$store.dispatch("setMostraBotoes", { imprimir: true, voltar: false });
    this.boleto = this.$store.state.boletoTimOferta;
    console.log(this.boleto);
    this.datahojeagora = this.datahoje();
    this.texto = ""; // this.boleto.qrcode.trim();
    //------------------------------------------
    let dados = {
      credor: 'TIM',
      cpf: this.boleto.cpf,
      contrato: this.boleto.contrato,
      valor: this.boleto.valor_pagar_str,
      venc: this.boleto.vencimento,
      fatura: null,
      codBarras: this.boleto.cb,
      L_dig: this.boleto.ld,
      remessa: null,
      parcelas_faltam: null,
      email: null,
      valor_entrada: null,
      valor_parcela: null,
    };
    console.log(dados)
    await api.post(`logs/gravaacordosomente`, dados);
    //------------------------------------------
  },
  async beforeDestroy() {
    this.$store.dispatch("setMostraBotoes", { imprimir: false, voltar: false });
  },
  async mounted() {
    console.log("textoo", this.texto);
    /* this.texto =
      "00020126740014br.gov.bcb.pix013686b26441-94eb-4523-9e8a-79120d007b9402128124016090165204000053039865406735.515802BR5907TIM S A6009SAO PAULO62290525R31812401609016010312432963048725";
    */
    barcode(window.$);
    window
      .$("#codb")
      .barcode({ code: `${this.boleto.cb}`, crc: false }, "int25", {
        barWidth: 1,
        barHeight: 50,
        showHRI: false,
      });
  },
  methods: {
    datahoje() {
      let dataagora = new Date();
      let dia = dataagora
        .getDate()
        .toString()
        .padStart(2, "0");
      let m = dataagora.getMonth();
      let mes = "";
      if (m == 0) {
        mes = "Janeiro";
      }
      if (m == 1) {
        mes = "Fevereiro";
      }
      if (m == 2) {
        mes = "Março";
      }
      if (m == 3) {
        mes = "Abril";
      }
      if (m == 4) {
        mes = "Maio";
      }
      if (m == 5) {
        mes = "Junho";
      }
      if (m == 6) {
        mes = "Julho";
      }
      if (m == 7) {
        mes = "Agosto";
      }
      if (m == 8) {
        mes = "Setembro";
      }
      if (m == 9) {
        mes = "Outubro";
      }
      if (m == 10) {
        mes = "Novembro";
      }
      if (m == 11) {
        mes = "Dezembro";
      }
      let ano = dataagora.getFullYear().toString();
      return `Rio, ${dia} de ${mes} de ${ano}`;
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 12px;
  background: white;
}

a img,
img {
  border: none;
}
ol,
ul {
  list-style: none;
  border: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
b,
strong {
  font-weight: bold;
}
hr {
  border: 0px none;
  height: 1px;
  background-image: -moz-linear-gradient(
    left center,
    transparent,
    rgba(0, 0, 0, 0.75),
    transparent
  );
  margin-top: 4px;
  margin-bottom: 4px;
}
button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
/* --------------------------------
		geral
--------------------------------- */

i {
  color: red;
  font-weight: normal;
}
.space {
  clear: both;
  padding: 5px;
  width: 17cm;
  margin: 0 auto;
}
.content {
  margin: 0 auto;
  width: 17cm;
  position: relative;
}
.col1 {
  width: 48%;
  float: left;
  text-align: left;
  padding-right: 1%;
}
.col2 {
  width: 48%;
  float: right;
  text-align: right;
  padding-left: 1%;
}
.long {
  min-height: 7.5cm;
}
.medium {
  min-height: 3cm;
}
.footer {
  position: relative;
  margin-top: 40px;
}
hr {
  border: 0;
  background-color: #fff;
  border-top: 1px dashed #000;
}
ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style-type: none;
}
code {
  color: #000;
  float: none;
  background: #fff none repeat scroll 0% 0%;
  font-weight: normal;
  font-size: 10px;
}
a {
  color: #2b75bf;
}
a:hover {
  color: #284a6b;
  text-decoration: none;
}
a:focus {
  color: #284a6b;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 5px;
}
/* --------------------------------
		Linhas tabelas e html
--------------------------------- */
table {
  font-weight: 700;
  width: 17cm;
  margin: 0 auto;
  border-collapse: collapse; /* CSS2 */
  background: #fff;
}
table b {
  font-weight: 800;
}
table td {
  vertical-align: top;
  min-width: 4cm;
  border: 2px solid black;
  padding: 5px;
}
table td .col11 {
  vertical-align: top;
  border: 2px solid black;
  padding: 5px;
}
table th {
  border: 12px solid black;
  background: #fff;
}
/* --------------------------------
			formularios
--------------------------------- */
input {
  float: right;
  background: #3498db;
  margin: 5px;
  border-radius: 4px;
  border: 0;
  color: #fff;
  font-size: 16px;
  padding: 8px;
  text-decoration: none;
  cursor: pointer;
}
input:hover {
  background: #1179be;
}
/* ==========================================================================
   Estilos para impressão
   ========================================================================== */
@media print {
  * {
    background: white !important;
    color: #000 !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  }
  .boloifixo {
    font: 12pt Georgia, "Times New Roman", Times, serif;
    color: #000;
  }
  h1 {
    font-size: 24pt;
  }
  h2 {
    font-size: 18pt;
  }
  h3 {
    font-size: 14pt;
  }
  q:after {
    content: " (" attr(cite) ")";
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a:link:after,
  a:visited:after {
    content: " (" attr(href) ") ";
  }
  p a {
    word-wrap: break-word;
  }
  p {
    widows: 3;
  }
  p {
    orphans: 3;
  }
  .page-break {
    page-break-before: always;
  }
  .notprint {
    visibility: hidden;
    display: none;
  }
  .pb {
    filter: -ms-grayscale(100%) !important;
    filter: -moz-grayscale(100%) !important;
    filter: -webkit-grayscale(100%) !important;
    filter: grayscale(100%) !important;
  }
}
.folha {
  page-break-after: always;
  max-height: 27.5cm;
  background-color: white;
}
* {
  font-size: 9pt;
}
.geral {
  padding: 8pt;
  background: #fff;
  width: 17cm;
  margin: 0 auto;
}
table td {
  border: none;
  font-weight: 300;
}
.banner {
  margin: 0 auto;
  height: 80px;
  display: table;
  line-height: 80px;
}
table td {
  padding: 2px;
}
</style>
